<template>
	<div class="tags-view-container">
		<scroll-pane ref="scrollPane" class="tags-view-wrapper">
			<div class="a-flex-rfsfe">
				<router-link v-for="tag in visitedViews" ref="tag" :class="isActive(tag) ? 'active' : ''"
					:to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }" :key="tag.path"
					class="tags-view-item " style="margin-left: 4px;" @click.middle.native="closeSelectedTag(tag)"
					@contextmenu.prevent.native="openMenu(tag, $event)">
					<span>{{ tag.title }}</span>
					<!-- <span v-show="visitedViews.length > 1" -->
					<span class="el-icon-close a-ml-08" style="width: 9px; height: 9px; padding-bottom: 3px"
						@click.prevent.stop="closeSelectedTag(tag)" />
				</router-link>
			</div>
		</scroll-pane>
		<!-- <ul v-show="visible && visitedViews.length > 1" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
			<li @click="refreshSelectedTag(selectedTag)">刷新</li>
			<li @click="closeSelectedTag(selectedTag)">关闭</li>
			<li @click="closeOthersTags">关闭其他</li>
			<li @click="closeAllTags">关闭所有</li>
		</ul> -->
	</div>
</template>

<script>
import Utils from "../../../utils";
import ScrollPane from "@/components/ScrollPane";
import {
	mapGetters,
	mapState,
	mapActions
} from "vuex";

export default {
	components: {
		ScrollPane,
	},
	data() {
		return {
			visible: false,
			top: 0,
			left: 0,
			selectedTag: {},
			whiteList: [
				'station-list', 
				'station-apply', 
				'station-construction', 
				// 'project-confirmed', 
				'device-type-edit', 
				'bank-card-add', 
				'device-list',
				'monthCard-list',
				'monthCard-relation',
				'order-list',
				'project-list',
				'price-list',
				'franchisee-view',
				'revenueBills-list',
				'businessAnalysis-list',
				'businessAnalysis-list-city',
				'businessAnalysis-list-business',
				'businessAnalysis-list-project',
				'businessAnalysis-list-station',
				'staff-manage',
				'withdrawal-view',
				'todolist-index',
				'sceneCheck-list',
				'device-status-list',
				'export-list',
				'package-order-list',
				'rfid-audit-list',
				'avtivity-list',
				'finance-szls-list',
				'finance-syrz-list',
				'finance-package-list',
				'finance-zq-list',
				'finance-repayment',
                'water-order-list',
                'finance-company-list',
                'chargeCabinet-order-list',
                'warning-list',
                'warning-manage-list',
                'warning-user-list',
                'observe-list',
                'electric-list',
                'electric-property-list',
                'electric-charge-list',
                'userMGT-complaint-list',
                'monthCard-temp-list',
                'coupon-list',
                'goodsOrder-list',
                'finance-repayment-dev-details',
                'finance-accounting-process',
                'finance-data-panel',
                'userMGT-car-audit',
                'workOrder-list',
                'inspection-list',
                'coupon-order-list',
                'data-inspection',
                'finance-reconciliation'
			]
		};
	},
	computed: {
		visitedViews() {
			return this.$store.state.tagsView.visitedViews;
		},
	},
	watch: {
		$route() {
			this.addViewTags();
			this.moveToCurrentTag();
		},
		visible(value) {
			if (value) {
				document.body.addEventListener("click", this.closeMenu);
			} else {
				document.body.removeEventListener("click", this.closeMenu);
			}
		},
	},
	mounted() {
		this.addViewTags();
	},
	methods: {
		// generateTitle, // generateTitle by vue-i18n
		isActive(route) {

			return route.path === this.$route.path;
		},
		addViewTags() {
			const {
				name
			} = this.$route;
			this.$route.meta.keepAlive = false;
			let isKeep = this.whiteList.filter((item, index) => { return item == name });
			if (isKeep.length == 1 && !this.$route.meta.hasOwnProperty('hash')) {
				this.$route.meta.keepAlive = true;
			}
			if (name) {
				this.$store.dispatch("addView", this.$route);
				this.$store.dispatch("addCachedView", this.$route);

			}
			if (this.$route.meta.hasOwnProperty('hash')) {
				delete this.$route.meta.hash;
			}
			return false;
		},
		moveToCurrentTag() {
			const tags = this.$refs.tag;


			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.path === this.$route.path) {
						this.$refs.scrollPane.moveToTarget(tag);
						//	this.$route.meta.keepAlive = true

						// when query is different then update

						if (tag.to.fullPath !== this.$route.fullPath) {
							this.$store.dispatch("updateVisitedView", this.$route);
						}
						break;
					}
				}
			});
		},
		refreshSelectedTag(view) {
			console.log("view123", view);

			this.$store.dispatch("delCachedViewThis", this).then(() => {
				const {
					fullPath
				} = view;
				console.log("fullPath", fullPath);
				this.$nextTick(() => {
					this.$router.replace({
						path: fullPath,
					});

				});
			});
		},
		closeSelectedTag(view) {
			if (this.isActive(view)) {
				view.meta.keepAlive = false;
				view.meta.hash = "del";
			}

			this.$store.dispatch("delCachedViewThis", this)
			console.log(this)
			this.$store.dispatch("delView", view).then(({
				visitedViews
			}) => {
				if (this.isActive(view)) {

					const latestView = visitedViews.slice(-1)[0];
					if (latestView) {
						this.$router.push(latestView);

					} else {
						this.$router.push("/");
					}
				}
			});
		},
		closeOthersTags() {
			this.$router.push(this.selectedTag);
			this.$store.dispatch("delOthersViews", this.selectedTag).then(() => {
				this.moveToCurrentTag();
			});
		},

		closeAllTags() {
			this.$store.dispatch("delAllViews");
			this.$router.push("/");
		},

		openMenu(tag, e) {
			const menuMinWidth = 105;
			const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
			const offsetWidth = this.$el.offsetWidth; // container width
			const maxLeft = offsetWidth - menuMinWidth; // left boundary
			const left = e.clientX - offsetLeft + 15; // 15: margin right

			if (left > maxLeft) {
				this.left = maxLeft;
			} else {
				this.left = left;
			}
			this.top = e.clientY;

			this.visible = true;
			this.selectedTag = tag;
		},
		closeMenu() {
			this.visible = false;
		},
	},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tags-view-container {
	height: 40px;
	width: 100%;
	background: #ebf0f5;

	//border-bottom: 1px solid #1B1D29;
	//box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

	.tags-view-wrapper {
		margin-top: 8px;

		.tags-view-item {
			padding: 0 16px;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			border: 1px solid #dadde0;
			border-bottom: 0px;
			height: 32px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			background: #f4f6f9;
			font-size: 12px;
			color: #606366;

			&.active {
				// background-color: rgb(64, 158, 255);
				color: #007aff;

				// border-color: #42b983;
				&::before {
					content: "";
					background: #fff;
					display: inline-block;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					position: relative;
					margin-right: 6px;
					background-color: #007aff;
					margin-bottom: 2px;
				}
			}
		}

		&:first-of-type {
			// margin-left: 32px;
		}
	}

	.contextmenu {
		margin: 0;
		background: #fff;
		z-index: 100;
		position: absolute;
		list-style-type: none;
		padding: 5px 0;
		border-radius: 4px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

		li {
			margin: 0;
			padding: 7px 16px;
			cursor: pointer;

			&:hover {
				background: #eee;
			}
		}
	}
}

.el-scrollbar__wrap {
	display: flex !important;
	flex-direction: row;
	justify-content: flex-start !important;
	align-items: flex-end !important;
	height: 40px !important;
	padding: 0px;
}

.el-scrollbar__view {
	display: inline-block !important;
}
</style>
