<template>
	<le-select-remote-search ref="selectCompany" :label="label" v-model="myValue" :options="myOptions" :multiple="multiple" :collapse="collapse" :isObj="isObj"
		:placeholder="placeholder" :notnull="notnull" :large="large" :readonly="readonly" />
</template>


<script>
import { mapState, mapActions } from 'vuex';
	export default {
		props:{
			value:{
				required:true,
			},
			placeholder:{
				type:String,
				default:'请选择'
			},
			label:{
				type:String
			},
			/**
			 * 是否必填
			 */
			notnull:{
				type:Boolean,
				default:false,
				required: false,
			},
			/**
			 * 是否为较长的输入框
			 */
			large:{
				type:Boolean,
				required:false,
				default:false
			},
            multiple:{
                type: Boolean,
				required: false,
				default: false
            },
            collapse:{
                type: Boolean,
				required: false,
				default: true
            },
			readonly:{
				type:Boolean,
				default:false
			},
            isObj: {
                type: Boolean,
                default: false
            },
			isSystem: {
                default: null
            }
		},
		data() {
			return {
				myValue:'',
				myOptions:{
					url: this.$Config.apiUrl.getUnderCompany,
					method: "post",
					showLoading: false,
					params: {
						isSignAgency: 1,
                        isSystem: this.isSystem
					},
					labelKey: "name",
					valueKey: "id",
					searchKey: "key",
				}
			}
		},
		watch:{
			value:{
				handler(val){
					this.myValue = val
				},
				immediate:true
			},
			'company.id':{
				deep: true,
				handler (newVal,oldVal) {
					// 切换商户后商户搜索id重置
					if(newVal != oldVal){
						// this.$refs['selectCompany'].resetCompamyChoose()
                        this.$emit('input', this.multiple?[]:'')
					}
				}
			},
			myValue:{
				handler(val,oldVal){
					if(val!=oldVal){
						this.$emit('input',val)
					}
				}
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company
			})
		},
		mounted() {
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
